/**
 * 封装axios
 */
import axios from 'axios'
import { Toast } from 'vant';
import base from '../api/base';

const install = axios.create({
    // baseURL : 'https://beexfx.xeoq.cn/api' ,  //网络请求的路径
    //baseURL:'http://192.168.101.136:8084/api',
    // baseURL: base.host,
    timeout:5000,
})

// 请求拦截器
install.interceptors.request.use(function(config){
    let user = JSON.parse(sessionStorage.getItem('user')) || ''

    if(user.token){
        config.headers.Authorization= 'Bearer ' + user.token;
    }else {
        return config
    }

    return config
},function(error){
    return Promise.reject(error)
})

// 响应拦截
install.interceptors.response.use(res => {
  // 对响应码的处理
  if (res.status === 401) {
    window.location.href = '/#/login';
  } else if(res.data.code == 500){
    Toast('服务端错误');
  } else if(res.data.code == -1){
    Toast(res.data.msg);
  }
  return res
}, err => {
    const { statusText, status } = err.response;
    console.log('err', err);
    if (status === 401) {
      window.location.href = '/#/login';
    } else {
      Toast(statusText);
    }
});

export default install