import Vue from 'vue'
import {Button} from 'vant'
import {Tabbar,TabbarItem} from 'vant'
import {Sticky} from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import { NoticeBar } from 'vant';
import { Icon } from 'vant';
import { Tab, Tabs } from 'vant';
import { Toast } from 'vant';
import { Tag } from 'vant';
import { Dialog } from 'vant';
import { Uploader } from 'vant';
import { Loading } from 'vant';
Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Sticky)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Tag);
Vue.use(Dialog)
Vue.use(Uploader);
Vue.use(Loading);
