import Vue from 'vue'
import  VueI18n from 'vue-i18n'


Vue.use(VueI18n)


let lang = sessionStorage.getItem("lang") 
if(!lang){
    sessionStorage.setItem('lang','en')
    lang = "en"
}

// 定义语言包
const messages = {
    zh_CN:require('@/lang/zh_cn'),
    en:require('@/lang/en'),
    zh_TW:require('@/lang/zh_tw')
}

const i18n = new VueI18n({
    locale:lang,
    messages,
})
export default i18n