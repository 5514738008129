import Vue from 'vue'
import Vuex from 'vuex'
import loginModule from './modules/loginModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language:'zh_CN'
  },
  getters: {
  },
  mutations: {
    changLang(state,newLang){
      state.language = newLang
    }
  },
  actions: {
  },
  modules: {
    loginModule
  }
})
