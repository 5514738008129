module.exports = {
    home:'home',
    deal:'deal',
    property:'property',
    my:'my',
    market_quotation:'market quotation',
    new_price:'pirce', 
    up_down:'change',
    pair:'pair',
    holding_assets:'holding assets',
    buy:'purchase',
    sale:'sale',
    top_price:'top price',
    bottom_price:'bottom price',
    login:'Welcome to login',
    login_btn:'login',
    forget:'forget the password',
    not_register:'not registered yet',
    email:'email',
    password:'password',
    registered_mail:'Registered mail',
    code:'verification code',
    get_code:'get code',
    read:'I have read and agree',
    user_agreement:'user agreement',
    register_btn:'register immediately',
    go_login:'You have an account. Go log in',
    login_success_msg:"login successfully",
    '待审核': 'checking',
    '已通过': 'pass',
    '已拒绝': 'reject',
    login_fail_msg:'input fails',
    sum_money:'Asset center',
    charge:'charge money',
    mention:'mention money',
    charge_record:'balance records',
    real_name:'real name authentication',
    withdrawal_record:'withdrawal record',
    layout:'sign out',
    total_assets:'Total Assets',
    spot_account:'Holding assets',
    available:'available',
    on_orders:'on orders',
    estimated:'current value',
    copy:'copy',
    add:'add',
    confirm:'confirm',
    cancel:'cancel',
    money:'money',
    input_money:'Please enter an amount',
    input_account:'Please enter an account',
    upload_id:'Please upload a picture of the front and back of your ID card',
    upload_explain:'Please make sure that the content of the photo is complete and clearly visible, only support jpg, png format, picture size is less than 5M:',
    positive:'positive',
    reverse:'reverse',
    top_up_contacts:'Complete the deposit via the wallet address below',
    service_explain:'Official customer service contact information',
    money_address:'Wallet address',
    withdrawal_account:'withdrawal currency',
    addKind:'Added a withdrawal currency',
    kind_explain:'You can add BTC, ETH, USDT and other wallet accounts',
    update:'revise',
    delete:'delete',
    upd_password:'Change the password',
    addTitle:'Add a new currency',
    updTitle:'Modify the currency',
    Currency:'Currency',
    copy_success:'Replication successful',
    not_copy:'The browser does not support automatic copying',
    back:'back',
    reload:'refresh',
    submit_id:'Submit for certification',
    to_be_reviewed:'To be reviewed',
    Approved:'Approved',
    Approval_rejection:'Approval rejection，Please continue to upload',
}