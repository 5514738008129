module.exports = {
    home: '首頁',
    deal: '交易',
    my: '我的',
    property:'資產',
    market_quotation:'市場行情',
    new_price:'最新價',
    up_down:'漲跌',
    pair:'交易對',
    holding_assets:'持有資產',
    buy:'申購',
    sale:'賣出',
    top_price:'最高價',
    bottom_price:'最低價',
    login:'歡迎登錄',
    login_btn:'登錄',
    forget:'忘記密碼',
    not_register:'立即註冊',
    email:'郵箱',
    password:'密碼',
    registered_mail:'郵箱註冊',
    code:'驗證碼',
    get_code:'獲取驗證碼',
    read:"我已閱讀並同意",
    user_agreement:'用戶協議',
    register_btn:'立即註冊',
    go_login:'已有賬號，前往登錄',
    login_success_msg:'登錄成功',
    login_fail_msg:'输入错误',
    '待审核': '待审核',
    '已通过': '已通过',
    '已拒绝': '已拒绝',
    sum_money:'資產中心',
    charge:'充幣',
    mention:'提幣',
    charge_record:'买卖記錄',
    real_name:'實名認證',
    withdrawal_record:'提幣記錄',
    layout:'退出登錄',
    total_assets:'總資產',
    spot_account:'持有資產',
    available:'可用的',
    on_orders:'認購中',
    estimated:'當前價值',
    copy:'復製',
    add:'增加',
    confirm:'確定',
    cancel:'取消',
    money:'金額',
    input_money:'請輸入金額',
    input_account:'請輸入賬戶',
    upload_id:'請上傳身份證正反面圖片',
    upload_explain:'請確保照片的內容完整並清晰可見，僅支持jpg、png格式， 圖片大小小於5M；',
    positive:'正面的',
    reverse:'反面的',
    top_up_contacts:'通過下方錢包地址完成充值',
    service_explain:'官方客服聯系方式',
    money_address:'錢包地址',
    withdrawal_account:'提幣幣種',
    addKind:'新增提幣幣種',
    kind_explain:'可添加BTC、ETH、USDT等錢包賬戶',
    update:'修改',
    delete:'刪除',
    upd_password:'修改密碼',
    addTitle:'新增幣種',
    updTitle:'修改幣種',
    Currency:'幣種',
    copy_success:'復製成功',
    not_copy:'該瀏覽器不支持自動復製',
    back:'返回',
    reload:'刷新',
    submit_id:'提交認證',
    to_be_reviewed:'待審核',
    Approved:'審核通過',
    Approval_rejection:'審核駁回,請繼續上傳'

}