/**
 * 
 */
const base = {
   // 网络地址
   // host: '/apihttp://192.168.101.136:111/api',
   host: '/apihttp://127.0.0.1:8000/api',
   // host: '/apihttps://beexfx.xeoq.cn/api',

   // 登录
   login: '/api/login',

   getHoldList: '/api/getHoldList',
   // 注册
   register: '/api/register',
   // 验证码
   verificationCode: '/api/verificationCode',
   // 切换语言
   setLocal: '/api/setLocal',
   // 退出
   logout: '/api/logout',
   // 首页数据
   tradingList: '/api/tradingList',
   //  轮播图
   image: '/api/image',
   // 新闻
   news: '/api/news',
   //  k线图
   getK: '/api/getK',
   //   充币账号
   bluntCoins: '/api/bluntCoins',
   // 提币
   withdrawal: '/api/withdrawal',
   // 充币，提币记录
   withdrawal_log: '/api/withdrawal_log',
   // 实名
   realName: '/api/realName',
   // 持有列表
   holdList: '/api/holdList',
   // 申购
   purchase: '/api/purchase',
   // 卖出
   sell: '/api/sell',
   // 获取资产
   me: '/api/me',
   // 客服
   service: '/api/service',

   // 详情的数据
   height: '/api/height',
   // 获取提现币种
   getUserTrad: '/api/getUserTrad',
   // 新增账户
   account: '/api/account',
   // 账户列表

   // 修改密码
   forgetPwd: '/api/forgetPwd',
   // 实名状态
   showRealName: '/api/showRealName',


}
export default base