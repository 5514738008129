/**
 * 
 */
import base from './base'
import axios from '../utils/request'

const api = {
       // 登录
       getLogin(params){
        return axios.post(base.login,params)
    },
     // 注册
     getRegister(params){
        return axios.post(base.register,params)
    },
    // 发送验证码
    verificationCode(params){
        return axios.post(base.verificationCode,params)
    },
    // 切换语言
    setLocal(params){
        return axios.get(base.setLocal + "/" + params)
    },
    logout(params){
        return axios.get(base.logout,params)
    },
    // 首页
    tradingList(params){
        return axios.get(base.tradingList,params)
    },
    // 轮播
    image(params){
        return axios.get(base.image,params)
    },
    // 新闻
    news(params){
        return axios.get(base.news,params)
    },

    getHoldList() {
        return axios.get(base.getHoldList);
    },
    getK(params){
        return axios.post(base.getK,params)
    },
    bluntCoins(params){
        return axios.get(base.bluntCoins,params)
    },
    // 提币
    withdrawal(params){
        return axios.post(base.withdrawal,params)
    },
    // 充币提币记录
    withdrawal_log(params){
        return axios.get(base.withdrawal_log + "?type=" + params)
    },
    // 实名
    realName(params){
        return axios.post(base.realName,params)
    },
    // 持有列表
    holdList(params){
        return axios.get(base.holdList,params)
    },
    // 申购
    purchase(params){
        return axios.post(base.purchase,params)
    },
    // 卖出
    sell(params){
        return axios.post(base.sell,params)
    },
     // 获取资产
     me(params){
        return axios.post(base.me,params)
     },
    //  客服
    service(params){
        return axios.get(base.service,params)
    },
    height(params){
        return axios.post(base.height,params)
    },
    // 获取提现币种
    getUserTrad(params){
        return axios.get(base.getUserTrad,params)
    },
    // 新增账户
    account(params){
        return axios.post(base.account,params)
    },
    // 账户列表
    accountList(params){
        return axios.get(base.account,params)
    },
    // 删除账户
    accountDel(params){
        return axios.delete(base.account+"/"+params)
    },
    // 编辑账户
    accountPut(params,name){
        return axios.put(base.account+"/"+params+"?name="+name)
    },
    // 编辑账户的回显
    accountShow(params){
        return axios.get(base.account+"/"+params+'/edit')
    },
    // 修改密码
    forgetPwd(params){
        return axios.post(base.forgetPwd,params)
    },
    // 实名状态
    showRealName(params){
        return axios.get(base.showRealName,params)
    }
   
    
}

export default api