import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path:'/login',
    name:'Login',
    component:()=>import('@/views/Login/index.vue')
  },
  {
    path:'/register',
    name:'Register',
    component:()=>import('@/views/Register/index.vue')
  },
  {
    path:'/forget',
    name:'forget',
    component:()=>import('@/views/Forget/index.vue')
  },
  {
    path:'/',
    name:'Layout',
    redirect: '/home',
    component:()=>import('@/views/Layout/index.vue'),
    children:[
      {
        path:'/home',
        name:'Home',
        component:()=>import('@/views/Home/index.vue')
      },
      {
        path:'/my',
        name:'My',
        component:()=>import('@/views/My/index.vue'),
        meta:{
          isLogin:true
        },
      },
      {
        path:'/deal',
        name:'Deal',
        component:()=>import('@/views/Deal/index.vue'),
        meta:{
          isLogin:true
        },
      },
      // 充币
      {
        path:'/charge',
        name:'Charge',
        component:()=>import('@/views/My/charge/index.vue'),
        meta:{
          isLogin:true
        },

      },
      // 提币
      {
        path:'/withdraw',
        name:'Withdraw',
        component:()=>import('@/views/My/withdraw/index.vue'),
        meta:{
          isLogin:true
        },

      },
      // 充币记录
      {
        path:'/chargeRecord',
        name:'chargeRecord',
        component:()=>import('@/views/My/chargeRecord.vue'),
        meta:{
          isLogin:true
        },
      },
      // 提币记录
      {
        path:'/withdrawRecord',
        name:'withdrawRecord',
        component:()=>import('@/views/My/withdrawRecord.vue'),
        meta:{
          isLogin:true
        },

      },
      {
        path:'/realName',
        name:'realName',
        component:()=>import('@/views/My/realName.vue'),
        meta:{
          isLogin:true
        },
      },
      {
        path:'/account',
        name:'account',
        component:()=>import('@/views/My/account.vue'),
        meta:{
          isLogin:true
        },
      }
    ]
  },
  {
    path:'/detail',
    name:'Detail',
    component:()=>import('@/views/Detail/index.vue')
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}



export default router
