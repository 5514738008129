import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/js/flexible'
// 配置vant
import './plugin/vant'
// 配置多语言
import i18n from './plugin/VueI18n/index'

import api from './api/index'
Vue.prototype.$api = api;
Vue.prototype.$cdn = (urlOrPath) => {
  if (`${urlOrPath}`.startsWith('http://') || `${urlOrPath}`.startsWith('https://')) {
    return urlOrPath;
  }
  return `//p-cdn.98pic.cn/${urlOrPath}`;
};

// 登录鉴权
import './router/permission'
import './utils/localStorage'

import {Locals} from '@/plugin/VueI18n/vantLang'

Vue.prototype.$Local = Locals

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
