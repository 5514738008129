module.exports = {
    home: '首页',
    deal: '交易',
    property:'资产',
    my: '我的',
    market_quotation:'市场行情',
    new_price:'最新价',
    up_down:'涨跌',
    pair:'交易对',
    holding_assets:'持有资产',
    buy:'申购',
    sale:'卖出',
    top_price:'最高价',
    bottom_price:'最低价',
    login:'欢迎登录',
    login_btn:'登录',
    forget:'忘记密码',
    not_register:'立即注册',
    email:'邮箱',
    password:'密码',
    registered_mail:'邮箱注册',
    code:'验证码',
    get_code:'获取验证码',
    read:"我已阅读并同意",
    user_agreement:'用户协议',
    register_btn:'立即注册',
    go_login:'已有账号，前往登录',
    login_success_msg:'登录成功',
    login_fail_msg:'输入错误',
    '待审核': '待审核',
    '已通过': '已通过',
    '已拒绝': '已拒绝',
    sum_money:'资产中心',
    charge:'充币',
    mention:'提币',
    charge_record:'买卖记录',
    real_name:'实名认证',
    withdrawal_record:'提币记录',
    layout:'退出登录',
    total_assets:'总资产',
    spot_account:'持有资产',
    available:'可用的',
    on_orders:'认购中',
    estimated:'当前价值',
    copy:'复制',
    add:'增加',
    confirm:'确定',
    cancel:'取消',
    money:'金额',
    input_money:'请输入金额',
    input_account:'请输入账户',
    upload_id:'请上传身份证正反面图片',
    upload_explain:'请确保照片的内容完整并清晰可见，仅支持jpg、png格式， 图片大小小于5M；',
    positive:'正面的',
    reverse:'反面的',
    top_up_contacts:' 通过下方钱包地址完成充值',
    service_explain:'官方客服联系方式',
    money_address:'钱包地址',
    withdrawal_account:'提币币种',
    addKind:'新增提币币种',
    kind_explain:'可添加BTC、ETH、USDT等钱包账户',
    update:'修改',
    delete:'删除',
    upd_password:'修改密码',
    addTitle:'新增币种',
    updTitle:'修改币种',
    Currency:'币种',
    copy_success:'复制成功',
    not_copy:'该浏览器不支持自动复制',
    back:'返回',
    reload:'刷新',
    submit_id:'提交认证',
    to_be_reviewed:'待审核',
    Approved:'审核通过',
    Approval_rejection:'审核驳回,请继续上传'

}