import { Locale } from "vant";
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhTW from 'vant/lib/locale/lang/zh-TW'

export function Locals(lang) {
  switch (lang) {
    case "zh_TW":
      Locale.use("zh_TW", zhTW);
      break;
    case "en":
      Locale.use("en", enUS);
      break;
    default:
      Locale.use("zh_CN", zhCN);
      break;
  }
}